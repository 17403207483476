# String methods

Object.defineProperty String.prototype, 'capitalize', value: -> @.replace /^(.)/, (_, char) => char.toUpperCase()

Object.defineProperty String.prototype, 'titleize', value: -> @.replaceAll /(^|_)(.)/g, (_, prec, char) => (if prec then ' ' else '') + char.toUpperCase()

Object.defineProperty String.prototype, 'camelize', value: -> @.replaceAll /_(.)/g, (_, char) => char.toUpperCase()

Object.defineProperty String.prototype, 'underscore', value: -> @.replaceAll /[A-Z]/g, (char) => '_' + char.toLowerCase()

Object.defineProperty String.prototype, 'compare', value: (string) ->
  splitNumbers = /[^0-9]+|[0-9]+/g; splitThis = this.match(splitNumbers); splitString = string.match(splitNumbers)
  index = splitThis.findIndex((string, index) => string.localeCompare(splitString[index]))
  if index == -1 then 0 else if index % 2 then splitThis[index] - splitString[index] else splitThis[index].localeCompare(splitString[index])
