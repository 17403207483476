# Object methods

Object.defineProperty Object, 'isEmpty', value: (object) ->
  false for _ of object
  true

Object.defineProperty Object, 'count', value: (object, callbackFn = (() => true), initialValue = 0) ->
  Object.sum object, ((value) => if callbackFn(value) then 1 else 0), initialValue

Object.defineProperty Object, 'sum', value: (object, callbackFn = ((item) => item), initialValue = 0) ->
  reduce object, ((sum, value) => sum + callbackFn(value)), initialValue

Object.defineProperty Object, 'min', value: (object, callbackFn = ((item) => item), initialValue = Infinity) ->
  Object.reduce object, ((min, value) => Math.min(min, callbackFn(value))), initialValue

Object.defineProperty Object, 'max', value: (object, callbackFn = ((item) => item), initialValue = -Infinity) ->
  Object.reduce object, ((max, value) => Math.max(max, callbackFn(value))), initialValue

Object.defineProperty Object, 'some', value: (object, callbackFn, thisArg) ->
  return true for key, value of object when callbackFn.call thisArg, value, key, object
  false

Object.defineProperty Object, 'every', value: (object, callbackFn, thisArg) ->
  return false for key, value of object when !callbackFn.call thisArg, value, key, object
  true

Object.defineProperty Object, 'find', value: (object, callbackFn, thisArg) ->
  return value for key, value of object when callbackFn.call thisArg, value, key, object
  undefined

Object.defineProperty Object, 'forEach', value: (object, callbackFn, thisArg) ->
  callbackFn.call thisArg, value, key, object for key, value of object
  undefined

Object.defineProperty Object, 'filter', value: (object, callbackFn, thisArg) ->
  result = {}
  result[key] = value for key, value of object when callbackFn.call thisArg, value, key, object
  result

Object.defineProperty Object, 'filterToArray', value: (object, callbackFn, thisArg) ->
  result = []
  result.push(value) for key, value of object when callbackFn.call thisArg, value, key, object
  result

Object.defineProperty Object, 'reduce', value: (object, callbackFn, initialValue) ->
  initialValue = callbackFn initialValue, value, key, object for key, value of object
  initialValue

Object.defineProperty Object, 'mapKeys', value: (object, callbackFn, thisArg) ->
  result = {}
  result[callbackFn.call thisArg, value, key, object] = value for key, value of object
  result

Object.defineProperty Object, 'mapValues', value: (object, callbackFn, thisArg) ->
  result = {}
  result[key] = callbackFn.call thisArg, value, key, object for key, value of object
  result

Object.defineProperty Object, 'mapEntries', value: (object, callbackFn, thisArg) ->
  result = {}
  Object.assign result, callbackFn.call thisArg, value, key, object for key, value of object
  result

Object.defineProperty Object, 'mapToArray', value: (object, callbackFn, thisArg) ->
  result = []
  result.push(callbackFn.call thisArg, value, key, object) for key, value of object
  result

Object.defineProperty Object, 'flatMap', value: (object, callbackFn, thisArg) ->
  result = []
  result = result.concat(callbackFn.call thisArg, value, key, object) for key, value of object
  result

Object.defineProperty Object, 'group', value: (object, callbackFn, thisArg) ->
  Object.reduce object, ((result, item, oldKey, object) =>
    key = callbackFn.call thisArg, item, oldKey, object
    if key of result then result[key].push(item) else result[key] = [item]
    result), {}

Object.defineProperty Object, 'transpose', value: (object, callbackFn, thisArg) ->
  Object.reduce object, ((result, array) =>
    array.forEach((item) =>
      key = callbackFn.call thisArg, item
      if key of result then result[key].push(item) else result[key] = [item])
    result), {}

Object.defineProperty Object, 'fill', value: (object, value = undefined, count = 0) ->
  Object.assign object, Array(count - Object.count(object)).fill(value)

Object.defineProperty Object, 'flatValues', value: (object, depth = 1) ->
  Object.values(object).flat(depth)

Object.defineProperty Object, 'sortValues', value: (object, compareFn = localeCompare) ->
  Object.values(object).sort(compareFn)

Object.defineProperty Object, 'concatValues', value: (result, objects...) ->
  for object in objects
    (result[key] = if result[key] then result[key].concat(value) else value) for key, value of object
  result

Object.defineProperty Object, 'assignValues', value: (result, callbackFn, objects...) ->
  for object in objects
    for key, values of object
      if result[key] then result[key][callbackFn(value)] = value for value in values
      else result[key] = values
  result

Object.defineProperty Object, 'camelizeEntries', value: (object) ->
  if object instanceof Array then object.map (value) => Object.camelizeEntries(value)
  else if object instanceof Object then Object.mapEntries object, (value, key) => {[key.camelize()]: Object.camelizeEntries(value)}
  else object

Object.defineProperty Object, 'underscoreEntries', value: (object) ->
  if object instanceof Array then object.map (value) => Object.underscoreEntries(value)
  else if object instanceof Object then Object.mapEntries object, (value, key) => {[key.underscore()]: Object.underscoreEntries(value)}
  else object
