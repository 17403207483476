# * eslint no-console:0 *//
# This file is automatically compiled by Webpack, along with any other files
# present in this directory. You're encouraged to place your actual application logic in
# a relevant structure within app/javascript and only use these pack files to reference
# that code so it'll be compiled.

# To reference this file, add <%= javascript_pack_tag 'tutorshop' %> to the appropriate
# layout file, like app/views/layouts/application.html.erb

# Uncomment to copy all static images under ../images to the output folder and reference
# them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
# or the `imagePath` JavaScript helper below.

images = require.context '../images', true
imagePath = (name) => images name, true

# import 'jquery'
import 'jquery-ui/ui/widgets/accordion'
import 'jquery-ui/ui/widgets/tabs'
import 'jquery-ui/ui/widgets/sortable'
import 'jquery-ui/ui/effects/effect-slide'

import 'javascript/jquery.delayed-1.1.0'
# import 'javascript/jquery.ui.touch-punch'
# import 'javascript/rails-ujs.prompt'

# import Rails from '@rails/ujs'
import Rails from 'javascript/rails-ujs'
# import Turbolinks from "turbolinks"
# import * as ActiveStorage from "@rails/activestorage"

import 'javascript/string'
import 'javascript/array'
import 'javascript/object'

import 'javascript/channels'
import 'javascript/controllers'

window.Rails = Rails
Rails.start()
# Turbolinks.start()
# ActiveStorage.start()
